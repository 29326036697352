import React from "react";
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import {FaSearch} from "react-icons/fa";
import { FaCheck,FaPlus } from "react-icons/fa6";

const BtnJaune = styled.button`
text-decoration: none;
border: none;
background: rgb(220, 253, 0); 
border-radius: 5px; padding: 20px; 
display: flex; 
flex-direction: row; 
gap: 10px; align-items: flex-end; 
justify-content: center;
align-items: center;
width: ${props => props.width || "240px"};
margin: ${props => props.margin || "0"};
position: relative;
cursor: pointer;color: rgb(10, 65, 120); 
text-align: left; font: 600 17px Quicksand, sans-serif; 
text-transform: uppercase;
transition: all .2s ease-out;
&> svg {
  display: none;
} 
&.search {
  &> svg.search {
    display: block;
  }
}  
&.check {
  &> svg.check {
    display: block;
  }
}  
&.links {
  align-items: center;
  text-align: center;
  &> svg {
    display: none;
  }
}

&.plus > svg.plus {
display: flex;
}

  &:hover {
    background: ${props => props.theme.bleuHP};
    &.withBorder {
      box-shadow: inset 0 0 0 1px #fff, rgba(0, 0, 0, 0.25) 0px 8px 4px 0px;
    }
    
    color: #fff;
    text-decoration: none;
}

@media screen and (max-width: 576px) {
    width: ${props => props.widthMob || "100%"};
    padding: 15px;
    margin: auto;
}
`;


export default function BoutonJauneHP(props) {
  const handleClick = () => {
    if (props.onClick) {
        props.onClick();
    }
    if (props.url) {
        window.location.href = props.url;
    }
};

    return <BtnJaune className={props.className} margin={props.margin} width={props.width} widthMob={props.widthMob} onClick={handleClick}><FaSearch className="search"/><FaPlus className="plus"/><FaCheck className="check"/>{props.libellé}</BtnJaune>   


}