import styled, {css} from 'styled-components';
import {Row, Col} from "reactstrap";
import Fade from 'reactstrap/lib/Fade';

export const DocContainer = styled.div`
box-shadow: rgba(0, 0, 0, 0.3) 0px -10px 8px -6px;
margin: 50px auto;
background: ${props => props.theme.bleuHP}; padding: 50px 100px; display: flex; flex-direction: column; gap: 50px; align-items: center; justify-content: center;
z-index: 2;
width: 100%;
&.gap10 {
  gap: 10px;
}
  @media (max-width: 768px) {
    padding: 50px 20px;
}
`;

export const TxtContainerDoc = styled.div`
padding: 10px 0px; display: flex; flex-direction: column; gap: 10px; align-items: flex-start; justify-content: flex-start;
  width: 100%;
`;

export const TitleDocs = styled.div`
color: rgb(255, 255, 255); text-align: left; font: 600 32px Unbounded, sans-serif;
  @media only screen and (max-width: 768px) {
  font-size: 24px;
  }
`;

export const TxtDocs = styled.div`
color: rgb(255, 255, 255); 
text-align: left; 
font: 400 18px 'Montserrat', sans-serif;
width: 100%;
`;

export const WrapperDocs = styled.div`
padding: 10px 0px; 
display: flex; 
align-items: center; 
justify-content: center;
flex-wrap:wrap;
width: 100%;
@media only screen and  (max-width: 992px) {
 flex-direction: column;
  gap: 20px;
  justify-content: center;
}
@media only screen and  (max-width: 768px) {
  justify-content: center;
}
`;

export const ImgDoc = styled.img`
height: 287px; 
`;
export const SpanTitreDoc = styled.span`
opacity: 0;
color: #fff;
font: 500 16px / 140% Unbounded, sans-serif;
letter-spacing: 0.3px;
text-align: center;
background: rgba(19, 26, 90, 0.65);
padding: 5px;
position: absolute;
width: 100%;
right: 0;
top: 0;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
transition: all 0.5s ease-in-out;
`

export const ImgDoc_2 = styled.img`
max-width: 204px;
margin: 9px;
@media (max-width: 1160px) {
  max-width: 180px;
}
@media (max-width: 992px) {
  max-width: 235px;
}
`;

export const Link1Doc = styled.div`
background: ${props => props.theme.beigeTresClair};
border-radius: 5px; 
padding: 10px; 
display: flex; 
gap: 5px; 
width: 35%;
align-items: center;
box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 40px 0px;
text-decoration: none !important;
border: none;
cursor: pointer;
&:hover {
  opacity: 0.92;
}
@media only screen and (max-width: 1415px) {
  flex-direction: column;
  }
  @media only screen and (max-width: 992px) {
  margin-bottom: 50px;
  width: 80%;
  margin: auto;
  }
@media only screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const Div2Doc = styled.div`
  padding: 10px 0px; 
  display: flex;
  align-items: center; 
  justify-content: center;
  width: 60%;
  flex-wrap: wrap;
  @media (max-width: 1130px) {
    justify-content: center;
  }
  @media (max-width: 992px) {
  width: 100%;
}
`;
export const Div4Doc = styled.div`
  padding: 10px 0px; 
  display: flex;
  align-items: center; 
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: 1130px) {
    justify-content: center;
  }
  @media (max-width: 992px) {
  width: 100%;
}
`;

export const Div3Doc = styled.button`
cursor: pointer;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
position: relative;
border: none;
margin: 10px;
&:hover {
  opacity: 0.8;
  &>${SpanTitreDoc} {
    opacity: 1;
  }
}
@media (max-width: 1160px) {
  margin: 10px;
}
@media (max-width: 576px) {
padding: 0;
}
`;

export const WrapperInfosDoc = styled.div`
padding: 10px; 
display: flex; 
flex-direction: column; 
gap: 10px; 
align-items: flex-start; 
padding-left: 45px;
justify-content: flex-start;
`;

export const LibDoc = styled.div`
  color: ${props => props.theme.bleuHP};text-align: left; font: 400 16px Unbounded, sans-serif;
`;

export const PdfInfo = styled.div`
 color: rgb(102, 102, 102);  font: 400 13px / 25px Unbounded, sans-serif;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const InfosDoc = styled.div`
padding:0; 
display: flex; flex-direction: column; gap: 10px; 
align-items: flex-start; justify-content: flex-start;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const WrapperDocumentations = styled.div`
padding: 50px 0px; display: flex; flex-direction: column; gap: 20px; align-items: center; justify-content: center; flex-shrink: 0; width: 100%; position: relative; overflow: hidden;
`;
export const WrapperDocumentations2 = styled.div`
padding: 50px 0px; display: flex; flex-direction: column; gap: 0px; align-items: center; justify-content: center; flex-shrink: 0; width: 100%; position: relative; overflow: hidden;
`;

export const StyledDivAds = styled.div`
&:first-of-type {
  box-shadow: 0 -10px 8px -6px rgba(0,0,0,0.3);
  padding-top: 80px;
}
&:last-of-type {
  padding-bottom: 130px;
}

padding: 50px 0px; 
display: flex; 
gap: 50px; 
align-items: center; 
justify-content: center; 
align-items: stretch; 
flex: 1 1 0%;
position: relative;
width: 100%; 
overflow: hidden;
flex-wrap:wrap;
&.links  {
  @media (max-width: 768px) {
    gap:0;
    padding: 50px 10px;
    }  
}
`;

export const Buts = styled.div`
display: flex;  align-items: center; justify-content: center;width: 100%; 
&.withGap {
  gap: 50px;
}
&.withGap20 {
  gap: 20px;
}
&.wrap {
  flex-wrap: wrap;
}
&.left {
  justify-content: flex-start;
}
 &.nextPrev {
  gap: 20px;
  &>div.wrap {
    display: flex;
    gap: 55px;
  }
 }
 &.column {
  flex-direction: column;
 }
@media (max-width: 768px) {
  flex-direction: column;
  &.nextPrev {
    gap: 20px;
  }
  &.withGap {
  gap: 20px;
  }
  &.wrap {
  flex-direction: row;
}
}
`;

export const DivWrap = styled.div`
`

export const StyledDivCat = styled.div`
background: rgb(255, 255, 255); 
border: 1px solid rgba(0, 0, 0, 0.1); 
padding: 0px 0px 50px; 
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center; 
flex-shrink: 0; 
width: 100%; 
position: relative; 
box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px; 
overflow: hidden;
`;

export const StyledDiv2Cat = styled.div`
background: rgb(255, 255, 255); 
padding: 10px; 
display: flex; flex-direction: column; gap: 10px; align-items: flex-start; justify-content: flex-start; flex-shrink: 0; 
width: 80%; 
margin: auto;
position: relative; 
overflow: hidden;
@media (max-width: 768px) {
  width: 90%;
  align-items: center;
  justify-content: center;
}
`;

export const Overlay = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: #0e1b46a1;
&.onLineCollab {
  background-color: ${props => props.theme.onLineCollab};
  &>${StyledDivCat} {
    border: 5px solid #3c460ea0 !important;
  }
}
`

export const Buttons = styled.div`
position: absolute;
bottom: 24px;
right: 24px;
z-index: 1;
display: flex;
flex-direction: column;
gap: 10px;
@media (max-width: 768px) {
  display: none;
}
`
export const ButtonsMobile = styled.div`
width: 100%;
margin-bottom: 10px;
display: none;
flex-direction: column;
gap: 10px;
&>a {
  text-align: center;
}
@media (max-width: 768px) {
  display: flex;
}
`

export const CatalogueContainer = styled.div`
box-sizing: border-box; width:100%;  background: rgb(255, 255, 255); 
padding: 100px 50px; display: flex; flex-direction: row; gap: 10px; align-items: center; justify-content: flex-start; flex-shrink: 0; position: relative;
@media (max-width: 1400px) {
  padding: 100px 40px;
  }
@media (max-width: 768px) {
  padding: 50px 20px;
  justify-content: center;
}
`;

export const RowCatalogue = styled.div`
flex-direction: ${props => props.mode === 'tableau' ? 'column' : 'row'};
width:100%;  
background: rgb(255, 255, 255); 
padding: 0px 10px; 
display: flex; 
gap: 100px; 
align-items: flex-start; 
justify-content: center; 
flex-shrink: 0; 
position: relative;
width: 100%;
@media (max-width: 1100px) {
  flex-direction: column;
  gap: 20px;
}
`;

export const WrapperFilters = styled.div`
box-sizing: border-box;  background: rgb(255, 255, 255); border-radius: 5px; 
padding: 35px; display: flex; flex-direction: column; gap: 24px; 
align-items: center; justify-content: center; 
flex-shrink: 0; position: relative; 
border: 1px solid rgba(0, 0, 0, 0.1);
margin: 10px;
/* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px 1px; */
overflow: hidden;
@media (max-width: 1170px) {
  padding: 30px;
}
@media (max-width: 992px) {
  padding: 15px;
}
`;

export const WrapperFiltersMode = styled.div`
display: flex; flex-direction: column; gap: 20px; align-items: flex-start; justify-content: flex-start; flex-shrink: 0; position: relative;width:100%;
&.mb50 {
  margin-bottom: 50px; 
}
`;

export const WrapperFiltersRevue = styled.div`
width:100%;
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: flex-start; 
justify-content: flex-start;
padding: 100px;
@media (max-width: 992px) {
padding: 50px 30px;
}
`;

export const WrapperProdListe = styled.div`
transition: opacity 1.5s ease-in-out;
width: 100%;
margin-bottom: 50px;
`;

export const RowFiltersMode = styled(Row)`
display: flex; 
flex-direction: row; 
align-items: center; 
justify-content: space-between; 
align-self: stretch; 
flex-shrink: 0; 
position: relative;
width:100%;
margin:0 !important;
&.fee {
  @media (max-width: 992px) {
  gap: 15px
}
}
`;

export const ColFiltersMode = styled(Col)`
  box-sizing: border-box;  display: flex; flex-direction: row; gap: 22px; align-items: flex-start; justify-content: flex-start; flex-shrink: 0; position: relative;margin:0 !important;padding:0 !important;
  @media (max-width: 768px) {
padding: 0 !important;
}
`;

export const ColButtonMode = styled.div`
padding: 0;
@media (max-width: 768px) {
padding: 0 !important;
}
`;

export const ButtonsMode = styled.div`
width:100%;  
padding: 10px 0px; 
display: flex; 
flex-direction: row; 
gap: 20px; 
align-items: 
flex-start; 
flex-shrink: 0; 
position: relative; 
overflow: hidden;
justify-content: space-between;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    margin-bottom: 50px;
    &>div:nth-child(3) {
    order:-1;
    width: 100%;
    }
    &>div:nth-child(1),&>div:nth-child(2) {
    width: 47%;
    }
  }
  @media (max-width: 992px) {
    flex-wrap: nowrap;
  }
  @media (max-width: 768px) {
  flex-wrap: wrap;
  &>div:nth-child(3) {
      order:-1;
      width: 100%;
      }
      &>div:nth-child(1),&>div:nth-child(2) {
      width: 48%;
    }
  }

  @media (max-width: 600px) {
    &>div:nth-child(1),&>div:nth-child(2),&>div:nth-child(3) {
      width: 100%;
    }
} 
`;

export const InfosEnteteWrapper = styled.div`
display: flex; gap: 0px; 
align-items: center; 
justify-content: space-between;
width: 100%;
  @media (max-width: 700px) {
    display: none;
  }
`;

export const Infos = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start; 
justify-content: center; 
`

export const Label = styled.img`
width: 90px;
@media (max-width: 700px) {
  width: 70px;
  }
`;

export const InfosArrows = styled.div`
box-sizing: border-box;  display: flex; flex-direction: row; gap: 5px; align-items: center; justify-content: center; flex-shrink: 0; position: relative;
&>svg {display: none;}
&.stable>svg.stable {display: flex;}
&.hausse>svg.hausse {display: flex;}
&.baisse>svg.baisse {display: flex;}
`;

export const ContainerInfos = styled.div`
display: flex; flex-direction: column; gap: 25px; align-items: flex-start; justify-content: center; align-self: stretch; flex-shrink: 0; position: relative;width:100%;
`;

export const Details = styled.div`
gap: 10px;
display: flex;
flex-direction: column;
font: 14px/130% "Epilogue", sans-serif;
color: ${props => props.theme.bleuHP};
padding: 15px 0px 10px 0px;
width: 100%;
position: relative;
overflow: hidden;
@media (max-width: 768px) {
  width: 100%;
}
`;

export const NomProd = styled.div`
padding: 10px 0;
&>svg {
  margin-left: 10px;
  color: ${props => props.theme.bleuHP};
  font-size: 32px;
}
`

export const Ste = styled.span`
color: ${(props) => props.theme.rose}; 
font: 400 20px / 140% Unbounded, sans-serif;
  @media (max-width: 768px) {
    text-align: left;
  }
`;

export const TitleProd = styled.div`
padding: 10px 10px 10px 0px; display: flex; flex-direction: column; gap: 10px; align-items: flex-start; justify-content: flex-start; flex-shrink: 0; position: relative; overflow: hidden;
  @media (max-width: 768px) {
    /* align-items: center; justify-content: center; */
  }
`;

export const ButtonsProd = styled.div`
background: rgb(255, 255, 255); padding: 10px 0; display: flex; gap: 20px; align-items: flex-start; justify-content: flex-start;
flex-wrap: wrap;
width: 100%;
  @media (max-width: 768px) {
    justify-content: center;
    &>a {
      width: 100%;
      margin: auto;
      justify-content: center;
    }
  }
`;

export const WrapperProduit = styled.div`
padding: 10px 0px; display: flex; flex-direction: column; gap: 25px; align-items: flex-start; justify-content: flex-start; flex-shrink: 0; position: relative; overflow: hidden;width: 100%;
`;

export const Car = styled.div`
color: ${props => props.theme.beigeFonce};
font-weight: 700;
`
// Splitting the WrapperImg component for better readability and maintenance

// Base styled component for WrapperImg
export const BaseWrapperImg = styled.div`
position: relative;
height: 456px;
width: 100%;
&::before {
  background: ${props => props.theme.rose};
}
`;

// Function to generate dynamic styles based on props
const generateDynamicStyles = props => {
  let commercialisationTxt;
  switch (props.$commercialisation) {
    case 2:
      commercialisationTxt = "Souscription clôturée";
      break;
    case 3:
      commercialisationTxt = "Réservation Possible";
      break;
    case 4:
      commercialisationTxt = "Marché secondaire";
      break;
    case 8:
      commercialisationTxt = "Absorbé";
      break;
    default:
      commercialisationTxt = "";
  }
  const commercialisation = props.$commercialisation;
  if (commercialisation !== -1 && commercialisation !== 0 && commercialisation !== 1 && commercialisation !== 5 && commercialisation !== 6 && commercialisation !== 7 && commercialisation !== 9) {
    return `
    &::before {
      content: "${commercialisationTxt}";
      position: absolute;
      display: block;
      top: 90px;
      left: 85px;
      width: 500px;
      max-width: none;
      padding: 3px;
      transform: translate(-50%, -50%) rotate(-45deg);
      
      font-size: 17px;
      font-family: inherit;
      white-space: pre;
      text-align: center;
      color: #fff;
      text-transform: uppercase;
      text-shadow: 0 0 1px #910606;
      z-index: 2;
    }
    `;
  }
  return '';
};

// Enhanced WrapperImg with dynamic styles
export const WrapperImg = styled(BaseWrapperImg)`
  background-color: ${props => props.theme.rose};
  ${props => generateDynamicStyles(props)}
`;
// export const WrapperImg = styled.div`
// position: relative;
// height: 456px;
// width: 100%;
// &::before {
//   background: ${props => props.theme.rose};
// }

// ${props => {
//     let commercialisationTxt;
//     switch (props.$commercialisation) {
//       case 2:
//         // commercialisationTxt = "Commercialisation\\A Termin\u00E9e";
//         commercialisationTxt = "Souscription cl\u00F4tur\u00E9e";
//         break;
//       case 3:
//         commercialisationTxt = "R\u00E9servation\\A Possible";
//         break;
//       case 4:
//         commercialisationTxt = "March\u00E9\\A secondaire";
//         break;
//       case 8:
//         commercialisationTxt = "Absorb\u00E9";
//         break;
//       default:
//         commercialisationTxt = "";
//     }
//     const commercialisation = props.$commercialisation;
//     return commercialisation !== -1 && commercialisation !== 0 && commercialisation !== 1 && commercialisation !== 5 && commercialisation !== 6 && commercialisation !== 7 && commercialisation !== 9 && css`
//       ::before {
//       content: "${commercialisationTxt}";
//         position: absolute;
//         display: block;
//         top: 90px;
//         left: 85px;
//         width: 500px;
//         max-width: none;
//         padding: 3px;
//         transform: translate(-50%, -50%) rotate(-45deg);
        
//         font-size: 17px;
//         font-family: inherit;
//         white-space: pre;
//         text-align: center;
//         color: #fff;
//         text-transform: uppercase;
//         text-shadow: 0 0 1px #910606;
//         z-index: 2;
//       }
//     `;
//   }}
// `

export const WrapperList = styled.div`
width:100%;  background: rgb(255, 255, 255); padding: 10px 0; display: flex; flex-direction: column; gap: 50px; align-items: flex-start; justify-content: flex-start; flex-shrink: 0; position: relative; overflow: hidden;width: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  &.show {
    opacity: 1;
  }
`;

export const InfoProdImmo = styled.div`
background: rgb(255, 255, 255); 
padding: 10px; 
display: flex; flex-direction: column; gap: 10px; align-items: flex-start; justify-content: flex-start;
width: 70%; 
margin: auto;
@media (max-width: 768px) {
  width: 90%;
  align-items: center;
  justify-content: center;
}
`;

export const ProduitImmo = styled.div`
background: rgb(255, 255, 255); border-style: solid; border-color: rgba(0, 0, 0, 0.1); border-width: 1px; padding: 0px 0px 50px; display: flex; flex-direction: column; gap: 50px; align-items: center; justify-content: center; flex-shrink: 0; width: 100%; position: relative; box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px; overflow: hidden;
`;

export const Fixed = styled.div`
position: -webkit-sticky;
position: sticky;
width: 100%;
top:20px;
bottom:-20px;
left:0;
z-index: 1; 
overflow-y: auto;
height: 100vh;
@media (max-width: 1100px) {
position: unset;
height: unset;
}
`

export const StyledDivRevue = styled.div`
background: rgb(255, 255, 255); 
padding: 50px 0px; display: flex; 
flex-direction: column; gap: 44px; align-items: center; justify-content: center; flex-shrink: 0; width: 100%; position: relative; overflow: hidden;margin: auto;
@media screen and (max-width: 992px) {
  padding: 50px 10px;
}
`;


export const WrapperRevue = styled.div`
width: 100%;
max-width: 100%;
padding: 10px 100px; 
display: flex;
gap: 80px; 
align-items: flex-start; 
justify-content: center; 
flex-shrink: 0; 
position: relative;
flex-wrap: wrap;
@media screen and (max-width: 992px) {
  max-width: 100%;
  padding: 10px 20px;
}
`;

export const ContainerBlog = styled.div`
box-sizing: border-box;  background: rgb(255, 255, 255); padding: 50px 10px; display: flex; flex-direction: column; gap: 44px; align-items: center; justify-content: center; flex-shrink: 0; width: 100%; position: relative; overflow: hidden;margin: auto;
`;

export const WrapperBlog = styled.div`
width: 100%;
max-width: 1400px;
padding: 10px 50px; 
display: flex; 
flex-direction: row; 
gap: 50px; 
align-items: center; 
justify-content: center; 
flex-shrink: 0; 
position: relative;
flex-wrap: wrap;
@media screen and (max-width: 992px) {
  max-width: 100%;
  padding: 10px 20px;
}
`;

export const StyledDivWrapper = styled.div`
background: rgb(255, 255, 255); padding: 100px 0px 0px; flex-direction: column; gap: 50px; align-items: center; justify-content: center; position: relative; overflow: hidden;
`;

export const StyledDivLinear = styled(Row)`
box-sizing: border-box;  padding: 100px; display: flex; flex-direction: row; gap: 100px; align-items: center; justify-content: center; flex-wrap:wrap; width: 100%; height: auto; position: relative; overflow: hidden;
background: linear-gradient(110deg, ${props => props.theme.bleuHP} 75%, rgb(194, 189, 166) 25%);margin: 0 0 50px;
&.news {
  margin: 0px 0 50px;
}
@media (max-width: 992px) {
  background: linear-gradient(110deg, ${props => props.theme.bleuHP} 72%, rgb(194, 189, 166) 50%);
  gap: 40px;
  padding: 50px 30px;
}
`;

export const StyledColLinear = styled(Col)`
display: flex; 
flex-direction: column;
gap: 30px; 
align-items: flex-start;
justify-content: flex-start; 
`;
export const StyledCol2Linear = styled(Col)`
display: flex;
flex-direction: column;
gap: 10px; 
align-items: center;
&.hideOnMob {
  @media (max-width: 768px) {
    display: none;
  }
}
`;

export const StyledCol2LinearMob = styled.div`
display: none;
@media (max-width: 768px) {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 100%;
}

`;

export const ContainerGraph = styled.div`
font-family: 'Unbounded', sans-serif;
width: 100%;
height: 300px;
min-height: 100%;
max-height: 100%;
margin-bottom: 110px;
&.ste {
  height: 480px;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  margin-bottom: 190px;
}

`
export const ContainerGraphPie = styled.div`
width: 100%;
height: auto;
@media (max-width: 576px) {
}
`

export const FadeStyled = styled(Fade)`
padding: 40px 0 0 0;
background-color: ${props => props.bckg || "transparent"};
border-radius: 5px;
&.noPadding {
  padding: 0;
}
&.fade:not(.show){
  height: 0px; 
  padding: 0;
  z-index: -1;
  position: relative;
}
&.fade {
  height: auto;
  transition: all .15s linear;
}
`

export const DivLettres = styled.div`
padding: 50px 0px;
display: flex;
flex-flow: wrap;
gap: 40px;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: center;
flex-shrink: 0;
width: 100%;
`

export const TagWrapper = styled.div`
padding: 10px 100px;
width: 100%;
display: flex;
flex-wrap: wrap;
justify-content: center;
background: ${props => props.theme.bleuHP};
`
export const Tags = styled.div`
background: ${props => props.theme.bleuHP};
padding: 35px 0;
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
@media (max-width: 768px) {
  display: none;
}
`

export const Blocs = styled(Row)`
  box-sizing: border-box;  padding: 0px 10px; display: flex;  flex-wrap: wrap; gap: 50px; align-items: flex-start; justify-content: center; flex-shrink: 0; position: relative;
  align-items: stretch;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const Bloc = styled(Col)`
  flex: 1 1 30%;
  font-size: 24px;
  background: rgb(255, 255, 255); 
  border-radius: 10px; 
  padding: 40px; 
  display: flex; 
  flex-direction: column; 
  gap: 44px; 
  align-items: center; 
  justify-content: flex-start; 
  /* max-width: 550px; */
  min-height: 450px;
  position: relative; 
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 40px 0px;
  @media screen and (max-width: 992px) {
    min-height: 570px;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
    min-height: auto;
    padding: 50px 0;
  }
`;

export const ImgWrapperBlog = styled.div`
display: flex;
align-items: center;
justify-content: center;
align-self: stretch; flex-shrink: 0; position: relative;transition: all .3s ease;transition: all .3s ease;height: 200px;overflow: hidden;
background-color: ${props => props.theme.bleuOverlay};
@media screen and (max-width: 768px) {
  height: auto;
}
`;

export const OverlayRevue = styled.div`
background: #1c275333;
width: 100%;
height: 100%;
position: absolute;
top: 0;
left: 0;
opacity: 0;
transition: all .2s ease;
&>svg{
  position: absolute;
  transition: all .1s ease;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  color: ${(props) => props.theme.rose};
  opacity: 0.5;
  font-size: 80px;
  z-index: 1;
}
`;

export const BlogWrapper = styled.div`
background: rgb(255, 255, 255); 
text-decoration: none;
display: flex; flex-direction: column; 
gap: 5px; 
align-items: flex-start; 
justify-content: flex-start;
width: 400px; 
min-height: 535px; 
max-height: 535px; 
position: relative; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 40px 0px; overflow: hidden;transition: all .3s ease;
  &.commentaires {
    min-height: 320px;
  }
  &:hover{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 40px 0px;
    text-decoration: none;
    /* &>${ImgWrapperBlog}>${OverlayRevue} {
    opacity: 1;
    }
    &>${ImgWrapperBlog}>img {
    transform: scale(1.2);
    } */
  }
  @media screen and (max-width: 768px) {
  width: 80%;
  }  
  @media screen and (max-width: 576px) {
  width: 100%;
  }
`;

export const BlocAvert = styled.a`
background: ${(props) => props.theme.bleuHP}; 
width: 600px;
margin: ${props => props.margin || '0'};
border-radius: 5px; 
padding: 49px 71px; 
display: flex; 
flex-direction: column;
gap: 44px; 
align-items: center; 
justify-content: center; 
flex-shrink: 0; 
position: relative; 
box-shadow: 0px 4px 40px 10px rgba(0, 0, 0, 0.25);
overflow: hidden;
text-decoration: none;
cursor: pointer;
border: none  !important;
&:hover {
  text-decoration: none;
  background: ${props => props.theme.rose};
  &>img {
    background: ${props => props.theme.rose};
  }
}

&.inverseColors {
  background: ${props => props.theme.rose};
  &:hover {
    text-decoration: none;
    &>img {
      background: ${props => props.theme.bleuHP};
    }
  }
}
&.rowDir {
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

  @media (max-width: 768px) {
    width: 100%;
    padding: 49px 10px;
  }
`;

export const DivChecks = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
width: 100%;
gap: 20px;
/* padding: 15px 0; */
&.radio {
  gap: 10px;
}
&.column {
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;

}
@media (max-width: 768px) {
  flex-direction: column;
  &.rowC {
    flex-direction: row;
  }
}
`
export const Check = styled.div`
`
export const Legal = styled.div`
padding: 20px 0;
text-align: justify;
font-family: 'Montserrat', sans-serif;
font-size: 16px !important;
&.absoluteForm {
  position: absolute;
  bottom: 0;
  color: #fff;
  padding: 20px;
  @media (max-width: 430px) {
    font-size: 14px !important;
  }
}
&> ul, &>ul> li {
  padding-left: 0;
  list-style-type: none;
  text-align: justify;
  font-size: 16px !important;
  font-family: 'Montserrat', sans-serif;
  
}
`
export const NoResult = styled.div`
width: 100%;
padding: 50px 0;
margin: 50px auto;
`

export const PiedPage = styled.div`
background-color: ${props => props.theme.beigeTresClair};
`
export const WrapperBlogs = styled.div`
padding:90px 50px 90px 50px; 
display: flex; 
flex-direction: column; 
gap: 10px; 
align-items: center; 
justify-content: center; 
flex-shrink: 0; 
position: relative; 
overflow: hidden;
width: 100%;
  @media (max-width: 768px) {
    padding: 50px 10px 90px; 
  }
`;

export const RowStyledForm = styled.div`
z-index: 9999;
justify-content: center;
width: 100%;
margin: auto;
align-items: center;
gap: 10px;
padding: 20px 40px;
&.bckg {
    background: rgb(255 255 255 / 98%);
}
@media (max-width: 768px) {
    width: 95%;
    padding: 10px 20px;
}
@media (max-width: 400px) {
    width: 95%;
    padding: 0px;
}
`

export const ButsForm = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: center;
gap: 20px;
margin-top: 20px;
`;